import { SET_LOADING, SET_LOADED, SCHEDULES_GET, SCHEDULES_GET_SUCCESS, BRANCHES_GET, BRANCHES_GET_SUCCESS,
  DATES_GET, DATES_GET_SUCCESS, DATES_GET_BY_BRANCH, DATES_GET_BY_BRANCH_SUCCESS, CREATE_APPOINTMENT, 
  CREATE_APPOINTMENT_SUCCESS, API_ERROR } from "./actionTypes";

export const setLoading = payload => {
  return {
    type: SET_LOADING,
    payload: payload,
  }
};

export const setLoaded = payload => {
  return {
    type: SET_LOADED,
    payload: payload,
  }
};

export const getSchedules = payload => {
  return {
    type: SCHEDULES_GET,
    payload: payload,
  }
};

export const getSchedulesSuccess = payload => {
  return {
    type: SCHEDULES_GET_SUCCESS,
    payload: payload,
  }
};

export const getBranches = payload => {
  return {
    type: BRANCHES_GET,
    payload: payload,
  }
};

export const getBranchesSuccess = payload => {
  return {
    type: BRANCHES_GET_SUCCESS,
    payload: payload,
  }
};

export const getDates = (scheduleId, branchId, strDate) => {
  return {
    type: DATES_GET,
    payload: { scheduleId, branchId, strDate },
  }
};

export const getDatesSuccess = payload => {
  return {
    type: DATES_GET_SUCCESS,
    payload: payload,
  }
};

export const getDatesByBranch = (scheduleId, branchId, strDate) => {
  return {
    type: DATES_GET_BY_BRANCH,
    payload: { scheduleId, branchId, strDate },
  }
};

export const getDatesByBranchSuccess = payload => {
  return {
    type: DATES_GET_BY_BRANCH_SUCCESS,
    payload: payload,
  }
};

export const createAppointment = (appointment, callback) => {
  return {
    type: CREATE_APPOINTMENT,
    payload: { appointment, callback },
  }
};

export const createAppointmentSuccess = payload => {
  return {
    type: CREATE_APPOINTMENT_SUCCESS,
    payload: payload
  }
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
};