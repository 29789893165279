import { get, post, put } from "../helpers/api_helper";
import * as url from "../helpers/url_helper";

const getAllSchedules = () => {
  return get(url.SCHEDULES)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data.error;
    });
};

const getAllSchedulesByDateAndBranch = (scheduleId, branchId, strDate) => {
    return get(url.SCHEDULES + '/' + scheduleId + '/branch/' + branchId + '/availability?strDate=' + strDate)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data.error;
    });
};

const getAllBranches = () => {
  return get(url.BRANCHES)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data.error;
    });
};

const getAllDates = (scheduleId, branchId, strDate) => {
  return get(url.DATES + '?scheduleId=' + scheduleId + '&branchId=' + branchId + '&strDate=' + strDate)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data.error;
    });
};

const insertAppointment = data => {
  return post(url.APPOINTMENTS, data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data.error;
    });
};

export {
  getAllSchedules,
  getAllSchedulesByDateAndBranch,
  getAllBranches,
  getAllDates,
  insertAppointment
};
