import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class Datos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      values: {
        firstName: '',
        lastName: '',
        dni: '',
        phone: '',
        email: '',
        confirmEmail: '',
        passport: '',
        libretaCivica: ''
      },
      customerNumber: null,
      phoneType: null
    };

    this.formRef = React.createRef();
  }

  isValidated() {
    return this.state.validated && this.state.phoneType;
  }

  handleChange(values) {
    setTimeout(function () {
      this.setState({ validated: this.formRef.current.isValid, values });
    }.bind(this), 100);
  }

  handleOrden(file) {
    this.setState({ customerNumber: file });
  }

  handleCobertura(file) {
    this.setState({ phoneType: file });
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          validateOnMount={true}
          innerRef={this.formRef}
          initialValues={{
            firstName: '',
            lastName: '',
            dni: '',
            phone: '',
            email: '',
            confirmEmail: '',
            libretaCivica: '',
            passport: ''
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required('El nombre es requerido'),
            lastName: Yup.string()
              .required('El apellido es requerido'),
            dni: Yup.string()
              .required('El DNI es requerido'),
            phone: Yup.string()
              .required('El teléfono es requerido'),
            libretaCivica: Yup.string()
              .required('La cobertura es requerida'),
            email: Yup.string()
              .email('El email es inválido')
              .required('El email es requerido'),
            confirmEmail: Yup.string()
              .oneOf([Yup.ref('email'), null], 'Los emails no coinciden')
              .required('Confirmar email es requerido'),
          })}>
          {({ values }) => (
            <Form onKeyUp={() => this.handleChange(values)}>
              <Row>
                <Col>
                  <div className="alert alert-info">
                    <strong>Por favor, complete los campos marcados con <span className="text-danger">*</span></strong>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <div className="form-group">
                    <label>Nombre <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="firstName"
                      type="text"
                    />
                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Apellido <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="lastName"
                      type="text"
                    />
                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>DNI <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="dni"
                      type="text"
                    />
                    <ErrorMessage name="dni" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Teléfono celular <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="phone"
                      type="text"
                    />
                    <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>Email <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="email"
                      type="text"
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Confirmar email <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="confirmEmail"
                      type="text"
                    />
                    <ErrorMessage name="confirmEmail" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>Cobertura <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="libretaCivica"
                      type="text"
                    />
                    <ErrorMessage name="libretaCivica" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Dirección y especificaciones (en caso de corresponder)</label>
                    <Field
                      className="form-control"
                      name="passport"
                      type="text"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>Orden médica</label><br/>
                    <input type="file" name="customerNumber" id="customerNumber" className="inputfile inputfile-custom" onChange={(e) => this.handleOrden(e.target.files[0])} />
                    <label htmlFor="customerNumber"><span>{this.state.customerNumber ? this.state.customerNumber.name : ''}</span> <strong><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> Adjuntar orden médica&hellip;</strong></label>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Carnet de cobertura</label><br />
                    <input type="file" name="phoneType" id="phoneType" className="inputfile inputfile-custom" onChange={(e) => this.handleCobertura(e.target.files[0])} />
                    <label htmlFor="phoneType"><span>{this.state.phoneType ? this.state.phoneType.name : ''}</span> <strong><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> Adjuntar carnet de cobertura&hellip;</strong></label>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          </Formik>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Datos);

