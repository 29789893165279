import React from "react";
// Redux
import { connect } from "react-redux";

import { Container, Row, Col } from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import S3 from "react-aws-s3";

import moment from "moment";

import logo from "../assets/images/logo.png";

import Emitter from "helpers/emitter_helper";

import Wizard from "components/Wizard";

import Tramite from "./Steps/Tramite";
import Disponibilidad from "./Steps/Disponibilidad";
import Sucursal from "./Steps/Sucursal";
import SucursalAlt from "./Steps/SucursalAlt";
import Fecha from "./Steps/Fecha";
import FechaAlt from "./Steps/FechaAlt";
import Datos from "./Steps/Datos";
import Confirmacion from "./Steps/Confirmacion";

import { setLoading, setLoaded, getBranches, createAppointment } from "store/actions";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      steps: [
        { stepName: "Trámite", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
        { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline" },
        { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
        { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
      ],
      breadcrumb: {
        tramite: {
          title: '',
          icon: 'mdi mdi-clipboard-text'
        },
        sucursal: {
          title: '',
          icon: 'mdi mdi-domain'
        },
        fecha: {
          title: '',
          icon: 'mdi mdi-calendar'
        }
      },
      success_msg: false,
      error_msg: false
    };

    const config = {
      dirName: process.env.REACT_APP_S3_DIRNAME,
      bucketName: process.env.REACT_APP_S3_BUCKET,
      s3Url: process.env.REACT_APP_S3_URL,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
    }

    this.ReactS3Client = new S3(config);
  }

  componentDidMount() {
    var stepsSucursal = [
      { stepName: "Trámite", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
      { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline" },
      { stepName: "Sucursal", component: Sucursal, stepIcon: "mdi mdi-domain" },
      { stepName: "Fecha y hora", component: Fecha, stepIcon: "mdi mdi-calendar" },
      { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
      { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
    ];

    var stepsFecha = [
      { stepName: "Trámite", component: Tramite, stepIcon: "mdi mdi-clipboard-text" },
      { stepName: "Disponibilidad", component: Disponibilidad, stepIcon: "mdi mdi-card-search-outline" },
      { stepName: "Fecha y hora", component: FechaAlt, stepIcon: "mdi mdi-calendar" },
      { stepName: "Sucursal", component: SucursalAlt, stepIcon: "mdi mdi-domain" },
      { stepName: "Datos", component: Datos, stepIcon: "mdi mdi-account-circle" },
      { stepName: "Confirmación", component: Confirmacion, stepIcon: "mdi mdi-check-circle" }
    ];

    Emitter.on('TYPE_UPDATED', (value) => {
      if (parseInt(value) == 1) {
        this.setState({ steps: stepsSucursal, type: parseInt(value) });
      } else {
        this.setState({ steps: stepsFecha, type: parseInt(value) });
      }
    });

    this.props.getBranches();
  }

  componentWillUnmount() {
    Emitter.off('TYPE_UPDATED');
  }

  async finish(allStates) {
    let payload = {
      branch: {
        id: allStates['Sucursal'].branch
      },
      schedule: {
        id: allStates['Sucursal'].schedule
      },
      startAt: allStates['Fecha y hora'].startAt,
      endAt: allStates['Fecha y hora'].endAt,
      customer: {
        firstName: allStates['Datos'].values.firstName,
        lastName: allStates['Datos'].values.lastName,
        dni: allStates['Datos'].values.dni,
        phone: allStates['Datos'].values.phone,
        email: allStates['Datos'].values.email,
        customerNumber: '',
        phoneType: '',
        libretaCivica: allStates['Datos'].values.libretaCivica,
        passport: allStates['Datos'].values.passport
      }
    }

    this.props.setLoading();

    if (allStates['Datos'].customerNumber) {
      let orden = await this.ReactS3Client
        .uploadFile(allStates['Datos'].customerNumber, payload.customer.dni + '-' + moment().format('DD-MM-YYYYHHmm'));

      payload.customer.customerNumber = orden.location;
    }

    if (allStates['Datos'].phoneType) {
      let credencial = await this.ReactS3Client
        .uploadFile(allStates['Datos'].phoneType, payload.customer.dni + '-credencial-' + moment().format('DD-MM-YYYYHHmm'));

      payload.customer.phoneType = credencial.location;
    }

    this.props.createAppointment(payload, (result) => this.onAppointmentCreate(result));
  }

  onAppointmentCreate(result) {
    this.props.setLoaded();
    
    if (result) {
      this.setState({ success_msg: true });
    } else {
      this.setState({ error_msg: true });
    }
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center mt-3 mb-3">
          <Col>
            <div className="text-center">
              <img src={logo} alt="Laboratorio Bioquímica Médica" className="img-fluid" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Wizard
              title="Solicitud de turnos"
              steps={this.state.steps}
              breadcrumb={this.state.breadcrumb}
              headerTextCenter
              validate
              color="primary"
              finishButtonText="Finalizar"
              finishButtonClick={this.finish.bind(this)}
              finishButtonClasses="btn-success"
              previousButtonText="Anterior"
              nextButtonText="Siguiente"
              nextButtonClasses="btn-primary"
              type={this.state.type}
              loading={this.props.loading}
            />
            {this.state.success_msg ? (
              <SweetAlert
                title="Gracias!"
                success
                confirmBtnBsStyle="success"
                confirmBtnText="Aceptar"
                onConfirm={() => window.location.reload() }
              >
                Su turno ha sido generado correctamente.
              </SweetAlert>
            ) : null}
            {this.state.error_msg ? (
              <SweetAlert
                title="Error!"
                error
                confirmBtnBsStyle="danger"
                confirmBtnText="Aceptar"
                onConfirm={() => this.setState({ error_msg: false })}
              >
                Ha ocurrido un error. Por favor, intente nuevamente
              </SweetAlert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { loading } = state.Home;

  return {
    loading
  };
}

export default connect(mapStateToProps, {
  setLoading,
  setLoaded,
  getBranches,
  createAppointment
})(Home);