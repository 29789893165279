import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { SCHEDULES_GET, BRANCHES_GET, DATES_GET, CREATE_APPOINTMENT, DATES_GET_BY_BRANCH } from "./actionTypes";
import { apiError, getSchedulesSuccess, getBranchesSuccess, getDatesSuccess, getDatesByBranchSuccess, 
  createAppointmentSuccess } from "./actions";

import {
  getAllSchedules,
  getAllBranches,
  getAllDates,
  insertAppointment
} from "../../services/appointments";

function* getSchedules() {
  try {
    const response = yield call(getAllSchedules);
    yield put(getSchedulesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getBranches() {
  try {
    const response = yield call(getAllBranches);
    yield put(getBranchesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getDates({ payload }) {
  try {
    const response = yield call(getAllDates, payload.scheduleId, payload.branchId, payload.strDate);
    yield put(getDatesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getDatesByBranch({ payload }) {
  try {
    const response = yield call(getAllDates, payload.scheduleId, payload.branchId, payload.strDate);
    response.branchId = payload.branchId;
    
    yield put(getDatesByBranchSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createAppointment({ payload: { appointment, callback } }) {
  try {
    const response = yield call(insertAppointment, appointment);
    yield put(createAppointmentSuccess(response));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* HomeSaga() {
  yield takeEvery(SCHEDULES_GET, getSchedules);
  yield takeEvery(BRANCHES_GET, getBranches);
  yield takeEvery(DATES_GET, getDates);
  yield takeEvery(DATES_GET_BY_BRANCH, getDatesByBranch);
  yield takeEvery(CREATE_APPOINTMENT, createAppointment);
}

export default HomeSaga;
