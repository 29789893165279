import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { find, filter, map, flatten } from "lodash";

import Emitter from "helpers/emitter_helper";

class Sucursal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: null,
      schedules: [],
      branches: [],
      branch: null,
      validated: false
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
    Emitter.on('RESET', (value) => {
      if (value) {
        this.setState({ branch: null, validated: false });
      }
    });

    Emitter.on('SCHEDULE_SELECTED', (value) => {
      if (value) {
        let filteredSchedules = filter(this.props.schedules, ['name', value]);
        let filteredBranches = [];

        filteredSchedules.forEach((fs) => {
          filteredBranches.push(map(fs.branches, 'id'));
        });

        filteredBranches = flatten(filteredBranches);
        let branches = filter(this.props.branches, (b) => {
          return filteredBranches.includes(b.id);
        });

        this.setState({ branches, schedules: filteredSchedules });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('RESET');
    Emitter.off('SCHEDULE_SELECTED');
  }

  setBranch(event) {
    if (this.state.branch && this.state.branch != event.target.value) {
      Emitter.emit('RESET', true);
    }

    let id = parseInt(event.target.value);

    let branch = find(this.props.branches, { id: id });
    Emitter.emit('BREADCRUMB_BRANCH', branch.name);

    // Una vez elegida la sucursal, podemos saber realmente el id del trámite
    let schedules = filter(this.state.schedules, (s) => {
      return !!find(s.branches, ['id', id]);
    });
    this.setState({ branch: id, schedule: schedules[0].id, validated: true });

    Emitter.emit('BRANCH_SELECTED', {
      branch: id,
      schedules: schedules
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          {this.state.branches.map((branch) => (
            <Col key={branch.id} md={6} xs={12}>
              <div className="custom-radio">
                <input id={'branch-' + branch.id} name="branches" type="radio" value={branch.id} checked={this.state.branch === branch.id} onChange={this.setBranch.bind(this)} />
                <label htmlFor={'branch-' + branch.id}>{branch.name}</label>
              </div>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, branches, error } = state.Home;
  return {
    loading, schedules, branches, error
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Sucursal);

