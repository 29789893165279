import { uniqBy, map, filter } from "lodash";
import moment from "moment";
import { SET_LOADING, SET_LOADED,SCHEDULES_GET, SCHEDULES_GET_SUCCESS, BRANCHES_GET, BRANCHES_GET_SUCCESS,
  DATES_GET, DATES_GET_SUCCESS, DATES_GET_BY_BRANCH, DATES_GET_BY_BRANCH_SUCCESS, 
  CREATE_APPOINTMENT, CREATE_APPOINTMENT_SUCCESS, API_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  schedules: [],
  groupedSchedules: [],
  branches: [],
  enabledDates: [],
  dates: [],
  enabledDatesByBranch: [],
  datesByBranch: []
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      state = { ...state, loading: true };
      break;
    case SET_LOADED:
      state = { ...state, loading: false };
      break;
    case SCHEDULES_GET:
      state = { ...state, loading: true, error: "" };
      break;
    case SCHEDULES_GET_SUCCESS:
      // TODO: solución temporaria a pedido para remover estos 3 trámites
      let filteredSchedules = filter(action.payload, (s) => {
        return !['Sedes provincia COVID-19 (solo uso interno)', 'Extracciones a Domicilio Análisis Clínicos', 'Extracciones a Domicilio COVID-19'].includes(s.name);
      });
      let groupedSchedules = uniqBy(filteredSchedules, 'name');

      state = { ...state, schedules: action.payload, groupedSchedules: groupedSchedules, error: "", loading: false };
      break;
    case BRANCHES_GET:
      state = { ...state, loading: true, error: "" };
      break;
    case BRANCHES_GET_SUCCESS:
      state = { ...state, branches: action.payload, error: "", loading: false };
      break;
    case DATES_GET:
      state = { ...state, dates: [], enabledDates: [], loading: true, error: "" };
      break;
    case DATES_GET_SUCCESS:
      let enabledDates = action.payload.slots.map((d) => {
        d.formattedDate = moment(d.startDate).format('YYYY-MM-DD');

        return d;
      }).filter(function (d) {
        return d.availability > 0;
      });
      enabledDates = map(uniqBy(enabledDates, 'formattedDate'), 'formattedDate');
      
      state = { ...state, enabledDates: enabledDates, dates: action.payload.slots, error: "", loading: false };
      break;
    case DATES_GET_BY_BRANCH:
      state = { ...state, datesByBranch: [], enabledDatesByBranch: [], loading: true, error: "" };
      break;
    case DATES_GET_BY_BRANCH_SUCCESS:
      let enabledDatesByBranch = action.payload.slots.map((d) => {
        d.branchId = action.payload.branchId;
        d.formattedDate = moment(d.startDate).format('YYYY-MM-DD');

        return d;
      }).filter(function (d) {
        return d.availability > 0;
      });
      let originalDatesByBranch = enabledDatesByBranch.concat(state.datesByBranch);
      enabledDatesByBranch = map(uniqBy(originalDatesByBranch, 'formattedDate'), 'formattedDate');
      
      state = { ...state, enabledDatesByBranch: enabledDatesByBranch, datesByBranch: originalDatesByBranch, error: "", loading: false };
      break;
    case CREATE_APPOINTMENT:
      state = { ...state, loading: true, error: "" };
      break;
    case CREATE_APPOINTMENT_SUCCESS:
      state = { ...state, loading: false, error: "" };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, enabledDates: [], enabledDatesByBranch: [], dates: [], 
        datesByBranch: [], loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default home;
